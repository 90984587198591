@import '../../../styles/variables';
@import '../../../styles/mixins';

.header {
	padding: 16px 0;
	background-color: $white;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	height: 80px;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);

	@include breakpoint($mobile, $smallDesktop) {
		padding: 18px 0;
		height: 48px;
		border-bottom: 1px solid $primary_lavender_25;
	}

	.container {
		@extend %commonContainer;

		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.logo {
		max-width: 200px;
		line-height: 0.5;

		@include breakpoint($mobile, $smallDesktop) {
			max-width: 110px;
		}

		@include breakpoint($smallDesktop, $desktop) {
			max-width: 100px;
		}

		img {
			width: 100%;
		}
	}

	.navigation {
		@include breakpoint($mobile, $smallDesktop) {
			display: none;
		}
	}

	.mobile-navigation {
		@include breakpoint($smallDesktop) {
			display: none;
		}
	}

	.actions {
		.action {
			& + .action {
				margin-left: 16px;
			}

			@include breakpoint($smallDesktop, $desktop) {
				&.login {
					width: 85px !important;
				}

				&.getGRC {
					width: 140px !important;
				}
			}
		}

		@include breakpoint($mobile, $smallDesktop) {
			display: flex;
			position: fixed;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: #ffffff;
			padding: 16px;
			justify-content: center;
			z-index: 10;

			.action {
				width: 48% !important;
			}
		}
	}
}
