@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.read-more {
	margin-top: 28px;

	@include breakpoint($mobile, $smallDesktop) {
		margin-top: 20px;
	}

	.text {
		cursor: pointer;
		border-radius: 4px;
		font-weight: $semiBold;
		font-size: 14px;
		color: $primary_blueberry_300;
		position: relative;
		padding-right: 20px;
		text-decoration: none;

		&:after {
			content: '';
			background: url('../../../assets/images/icon-navigation.svg') no-repeat;
			background-size: contain;
			width: 14px;
			height: 14px;
			margin-left: 4px;
			display: inline-block;
			position: absolute;
			top: calc(50% - 7px);
		}
	}

	&:hover {
		.text {
			background-color: $primary_lavender_50;
		}
	}
}
