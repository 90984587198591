@import '../../../styles/variables';
@import '../../../styles/mixins';

.top {
	background-color: $primary_lavender_25;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;

	@include breakpoint($mobile, $smallDesktop) {
		padding-bottom: 40px;
	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		width: 223px;
		height: 49px;
		background-color: $primary_lavender_50;
		border-bottom-left-radius: 32px;
		border-bottom-right-radius: 32px;
		z-index: 0;
		animation: 1s ease-out 0s 1 slideInIso;

		@include breakpoint($mobile, $smallDesktop) {
			top: 30%;
			left: 0;
			width: 20px;
			height: 113px;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 32px;
			border-top-right-radius: 32px;
		}
	}

	&:after {
		content: '';
		position: absolute;
		bottom: -55px;
		right: 0;
		width: 100px;
		height: 113px;
		background-color: $primary_lavender_50;
		border-top-left-radius: 32px;
		border-bottom-left-radius: 32px;
		z-index: 0;
		animation: 1s ease-out 0s 1 slideInNist;

		@include breakpoint($smallDesktop, $desktop) {
			width: 60px;
		}

		@include breakpoint($mobile, $smallDesktop) {
			display: none;
		}
	}

	.content {
		@extend %commonContainer;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		z-index: 1;
		padding-top: 70px;
		padding-bottom: 80px;

		@include breakpoint($smallDesktop, $desktop) {
			justify-content: center;
			padding-top: 48px;

			.info {
				margin-right: 48px;
			}
		}

		@include breakpoint($mobile, $smallDesktop) {
			flex-direction: column;
			padding-top: 48px;
			padding-bottom: 48px;
		}

		.info {
			max-width: 600px;

			h1 {
				margin: -80px 0 32px;
				animation: 1s ease-out 0s 1 slideInLeft;

				.highlighted {
					color: $primary_lavender_200;
					display: block;
				}

				@include breakpoint($smallDesktop, $desktop) {
					margin: 24px 0 24px 0;
				}

				@include breakpoint($mobile, $smallDesktop) {
					margin: 0 0 24px 0;
				}
			}

			p {
				animation: 1s ease-out 0s 1 slideInLeftLess;
			}
		}

		.frameworks {
			width: 443px;
			height: 462px;
			padding: 11px 11px 15px 15px;
			background: url('../../../assets/images/partial-rest.png') no-repeat;
			background-size: contain;
			position: relative;
			margin-right: 32px;

			@include breakpoint($mobile, $smallDesktop) {
				width: 280px;
				height: 300px;
				margin-top: 88px;
				margin-right: 0;
			}

			@include breakpoint($smallDesktop, $desktop) {
				width: 304px;
				height: 317px;
				margin-top: 88px;
				margin-right: 0;
			}

			&:before {
				content: '';
				position: absolute;
				top: -22px;
				left: -26px;
				background: url('../../../assets/images/partial-iso.png') no-repeat;
				background-size: contain;
				width: 254px;
				height: 263px;
				animation: 1s ease-out 0s 1 slideInIso;

				@include breakpoint($mobile, $desktop) {
					width: 61%;
					height: 60%;
				}
			}

			&:after {
				content: '';
				position: absolute;
				bottom: -47px;
				right: -41px;
				background: url('../../../assets/images/partial-nist.png') no-repeat;
				background-size: contain;
				width: 270px;
				height: 280px;
				animation: 1s ease-out 0s 1 slideInNist;

				@include breakpoint($mobile, $desktop) {
					width: 70%;
					height: 70%;
					bottom: -16%;
					right: -17%;
				}
			}
		}
	}
}

@keyframes slideInIso {
	0% {
		transform: translate(-30px, -30px);
	}
	100% {
		transform: translate(0);
	}
}

@keyframes slideInNist {
	0% {
		transform: translate(30px, 30px);
	}
	100% {
		transform: translate(0);
	}
}

@keyframes slideInLeft {
	0% {
		transform: translateX(-50%);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes slideInLeftLess {
	0% {
		transform: translateX(-30%);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}
