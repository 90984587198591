//Resolutions
//Resolutions
$mobile: 320px;
$tablet: 769px;
$smallDesktop: 1024px;
$desktop: 1270px;
$mediumDesktop: 1440px;
$largeDesktop: 1920px;

//Font weights
$light: 300;
$normal: 400;
$medium: 500;
$semiBold: 600;
$demiBold: 700;
$bold: 900;

// /*Color variables
// *Every color has it`s name
// *For taking colors names was using this service "http://chir.ag/projects/name-that-color" */
$black: #000;
$white: #fff;
$gray: #8d8d8d;
$alto: #d9d9d9;

//---

$primary_lavender_15: #FCFCFF; // check with ui kit 
$primary_lavender_25: #f9f9ff;
$primary_lavender_50: #efedfc;
$primary_lavender_75: #bdb4f1;
$primary_lavender_100: #a195eb;
$primary_lavender_200: #7967e3;
$primary_lavender_300: #5d48dd;
$primary_lavender_400: #41329b;
$primary_lavender_500: #392c87;

$primary_blueberry_50: #e7e7eb;
$primary_blueberry_75: #9d9dac;
$primary_blueberry_100: #757589;
$primary_blueberry_200: #393957;
$primary_blueberry_300: #111134;
$primary_blueberry_400: #0c0c24;
$primary_blueberry_500: #0a0a20;
