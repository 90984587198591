@import '../../../styles/mixins';
@import '../../../styles/variables';

.navigation {
	display: flex;
	flex-direction: row;
	align-items: center;

	.item {
		flex-grow: 1;
		font-size: 1em;
		line-height: 1.5;
		color: $primary_blueberry_300;
		padding: 12px 8px;
		font-weight: $normal;
		border-radius: 0;
		border-bottom: 2px solid transparent;
		white-space: nowrap;
		transition: all 0.2s linear;
		cursor: pointer;

		&:hover,
		&.active {
			background: none;
			border-bottom-color: $primary_blueberry_300;
			text-shadow:
				0 0 0.5px $primary_blueberry_300,
				0 0 0.5px $primary_blueberry_300,
				0 0 0.5px $primary_blueberry_300;
		}

		&:hover {
			border-bottom-color: transparent;
		}

		&.active {
			pointer-events: none;
			cursor: default;
			border-bottom-color: $primary_blueberry_300;
		}

		& + .item {
			margin-left: 24px;

			@include breakpoint($smallDesktop, $desktop) {
				margin-left: 16px;
			}
		}
	}

	.btn {
		margin-left: 16px;
	}
}
