@import '../../../styles/variables';
@import '../../../styles/mixins';

.button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 36px;
	font: {
		size: 14px;
		weight: $semiBold;
	}
	line-height: 20px;
	cursor: pointer;
	text-decoration: none;
	transition: all 0.3s;
	outline: none;
	margin: 0;
	border: 2px solid $primary_lavender_300;
	border-radius: 24px;
	background-color: $primary_lavender_300;
	width: 100%;

	&.small {
		height: 32px;
		font-size: 12px;
		line-height: 16px;
	}

	.text {
		color: $primary_lavender_25;
	}

	&:hover {
		border-color: $primary_lavender_200;
		background-color: $primary_lavender_200;
	}

	&:disabled {
		cursor: auto;
		border-color: $primary_lavender_75;
		background-color: $primary_lavender_75;
		pointer-events: none;
	}

	&.negative {
		background-color: transparent;
		border-color: $primary_lavender_300;

		.text {
			color: $primary_lavender_300;
		}

		&:disabled {
			border-color: $primary_lavender_75;

			.text {
				color: $primary_lavender_75;
			}
		}

		&:hover {
			border-color: $primary_lavender_200;
			background-color: $primary_lavender_50;

			.text {
				color: $primary_lavender_200;
			}
		}
	}
}
