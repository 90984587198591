@import '../../../styles/variables';
@import '../../../styles/mixins';

.tag {
	display: inline-block;
	text-transform: uppercase;
	margin: 2px;
	border: 1px solid transparent;
	white-space: nowrap;
	border-radius: 2px;
	background: $white;
	font-size: 5px;
	font-weight: $medium;
	line-height: 5px;
	padding: 1.5px 4px;

	@include breakpoint($mobile, $smallDesktop) {
		font-size: 3px;
        line-height: 3px;
        padding: 1px 3px;
		border-width: 0.5px;
	}
}
