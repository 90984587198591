@import '../../../styles/variables';
@import '../../../styles/mixins';

.consulting {
	padding: 85px 0;
	background-color: white;
	position: relative;

	&:before {
		content: '';
		position: absolute;
		top: -220px;
		left: -130px;
		width: 390px;
		height: 450px;
		opacity: 0.06;
		background: url('../../../assets/images/symbol-gravity-gradient.png') no-repeat;
		background-size: contain;

		@include breakpoint($mobile, $desktop) {
			top: -110px;
			left: -65px;
			width: 195px;
			height: 225px;
		}
	}

	&:after {
		content: '';
		position: absolute;
		top: 76%;
		right: 0;
		width: 120px;
		height: 313px;
		background-color: $primary_lavender_50;
		border-top-left-radius: 24px;
		border-bottom-left-radius: 24px;
		z-index: 0;

		@include breakpoint($mobile, $desktop) {
			display: none;
		}
	}

	@include breakpoint($mobile, $desktop) {
		padding: 45px 0;
	}

	.container {
		@extend %commonContainer;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;

		h2 {
			margin-bottom: 32px;
		}

		.info {
			text-align: center;
			max-width: 596px;
			margin-bottom: 56px;
			position: relative;

			&:after {
				content: '';
				width: 80%;
				height: 2px;
				border-radius: 3px;
				background-color: $primary_lavender_100;
				position: absolute;
				bottom: -28px;
				left: 10%;
			}

			@include breakpoint($mobile, $smallDesktop) {
				margin-bottom: 40px;
				max-width: 90%;

				&:after {
					bottom: -20px;
				}
			}

			p {
				margin-top: 24px;
				color: $primary_blueberry_200;
			}
		}

		.columns {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			width: 80%;
			position: relative;

			.column {
				width: 46%;

				p {
					text-indent: 30px;

					&:first-of-type {
						margin-bottom: 20px;
					}
				}
			}

			@include breakpoint($mobile, $smallDesktop) {
				flex-direction: column;
				width: 90%;

				.column {
					width: 100%;

					& + .column {
						margin-top: 20px;
					}
				}
			}
		}
	}
}
