@import '../../../styles/mixins';
@import '../../../styles/variables';

.mobile-navigation {
	display: flex;
	flex-direction: row;
	align-items: center;

	.toggler {
		width: 15px;
		height: 10px;
		background: url('../../../assets/images/icon-mobile-menu.svg') no-repeat;
		background-size: cover;
		cursor: pointer;

		&.opened {
			width: 10px;
			height: 10px;
			background: url('../../../assets/images/icon-close.svg') no-repeat;
			background-size: cover;
			cursor: pointer;
		}
	}

	.popup {
		position: fixed;
		top: 48px;
		bottom: 0;
		left: 0;
		right: 100%;
		display: flex;
		flex-direction: column;
		transition: all 0.3s linear;
		background: #ffffff;
		visibility: hidden;
		z-index: -10;
		padding: 16px;

		.item {
			padding: 12px;
			font-size: 16px;
			line-height: 24px;
			color: $primary_blueberry_300;
			font-weight: $normal;
			cursor: pointer;
			white-space: nowrap;
			visibility: hidden;
			opacity: 0;
			transition: all 0.2s linear;

			&.active {
				font-weight: $bold;
			}
		}

		&.open {
			right: 0;
			visibility: visible;
			z-index: 10;

			.item {
				visibility: visible;
				opacity: 1;
			}
		}
	}
}
