@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.feature-item {
	position: relative;
	width: 496px;
	padding: 24px 0;

	@include breakpoint($mobile, $smallDesktop) {
		width: 100%;
		padding: 8px 0;
		margin-bottom: 12px;
	}

	.text {
		cursor: pointer;
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		font-size: 1.3em;
		font-weight: $semiBold;
		color: $primary_blueberry_200;
		transition:
			opacity 0.3s linear,
			font-size 0.3s linear;

		&:after {
			display: block;
			width: 0;
			content: '';
			border: 15px solid transparent;
			position: absolute;
			border-right-color: $primary_lavender_50;
			border-left: 0;
			left: calc(100% + 28px);
			top: calc(50% - 13px);
			transition: opacity 0.1s linear;
			opacity: 0;

			@include breakpoint($mobile, $smallDesktop) {
				display: none;
			}
		}

		.text-item {
			flex-grow: 1;
			padding-left: 12px;

			@include breakpoint($smallDesktop) {
				&.multiline {
					width: min-content;
					min-width: 370px;
				}
			}
		}

		.line {
			min-width: 14px;
			width: 14px;
			height: 2px;
			background-color: $primary_lavender_100;
			border-radius: 4px;
			transition: all 0.2s linear;
			position: relative;
			top: 14px;

			@include breakpoint($mobile, $smallDesktop) {
				top: 10px;
			}
		}

		&.active {
			font-size: 1.5em;
			font-weight: $demiBold;
			color: $primary_blueberry_300;

			@include breakpoint($mobile, $smallDesktop) {
				margin-bottom: 12px;
			}

			.text-item {
				flex-grow: 0;

				@include breakpoint($mobile, $smallDesktop) {
					padding-left: 0;
					margin-left: -6px;
				}
			}

			.line {
				width: auto;
				flex-grow: 1;

				@include breakpoint($mobile, $smallDesktop) {
					flex-grow: 0;
					width: 30px;
					position: relative;
					left: -18px;
				}
			}

			&:after {
				opacity: 1;
			}
		}
	}

	.bubble {
		position: absolute;
		top: -4px;
		left: calc(100% + 28px + 15px);
		border-radius: 24px;
		background-color: $primary_lavender_50;
		width: 100%;
		padding: 32px;
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.3s linear;
		height: 0;

		@include breakpoint($mobile, $smallDesktop) {
			position: relative;
			top: unset !important;
			left: unset !important;
			bottom: unset !important;
			width: 100%;
			padding: 0;
		}

		&.pre-last {
			top: -160%;

			@include breakpoint($smallDesktop, $desktop) {
				top: -70%;
			}
		}

		&.last {
			top: unset;
			bottom: -14px;

			@include breakpoint($smallDesktop, $desktop) {
				bottom: -8px;
			}
		}

		&.active {
			visibility: visible;
			opacity: 1;
			height: auto;

			@include breakpoint($mobile, $smallDesktop) {
				padding: 24px;
			}
		}
	}
}
