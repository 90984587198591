@import '../../../styles/variables';
@import '../../../styles/mixins';

.about {
	background-color: $primary_lavender_25;
	padding-bottom: 200px;

	@include breakpoint($mobile, $desktop) {
		padding-bottom: 56px;
	}

	.container {
		@extend %commonContainer;
		padding-top: 96px;
		padding-bottom: 40px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	h2 {
		text-align: center;
	}

	.content {
		background-image: url('../../../assets/images/about-image.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: 41%;
		max-width: 1144px;
		width: 94%;
		height: 639px;
		position: relative;
		margin-top: 72px;
		z-index: 1;

		@include breakpoint($mobile, $desktop) {
			width: 100%;
			height: auto;
			background: none;
			margin-top: 40px;
		}

		.block {
			padding: 40px 35px;
			width: 496px;
			background-color: $white;
			border: 1px solid $primary_lavender_50;
			border-radius: 24px;
			position: absolute;

			&.top-left {
				top: 0;
				left: 0;

				@include breakpoint($mobile, $desktop) {
					width: 95%;
				}
			}

			&.top-right {
				top: 10%;
				right: 0;

				@include breakpoint($mobile, $desktop) {
					width: 90%;
				}
			}

			&.bottom-left {
				bottom: 0;
				left: 0;

				@include breakpoint($mobile, $desktop) {
					width: 95%;
				}
			}

			&.bottom-right {
				bottom: -17%;
				right: 0;

				@include breakpoint($mobile, $desktop) {
					width: 85%;
				}
			}

			@include breakpoint($mobile, $desktop) {
				position: relative;
				top: unset;
				bottom: unset;
				right: unset;
				left: unset;
				padding: 16px;
				margin-bottom: 16px;
			}

			@include breakpoint($mobile, $smallDesktop) {
				width: 100%;
			}

			@include breakpoint($smallDesktop, $desktop) {
				width: 80%;
			}
		}
	}

	@include breakpoint($mobile, $desktop) {
		position: relative;
		overflow: hidden;

		.container {
			padding-top: 56px;
			padding-bottom: 16px;
		}

		&:after {
			content: '';
			width: 483px;
			height: 502px;
			background-image: url('../../../assets/images/about-image.png');
			background-size: contain;
			background-repeat: no-repeat;
			position: absolute;
			top: 30%;
			z-index: 0;
		}
	}

	@include breakpoint($mobile, $smallDesktop) {
		&:after {
			right: -60%;
		}
	}

	@include breakpoint($smallDesktop, $desktop) {
		&:after {
			right: -20%;
		}
	}
}
