@import '../../../../styles/variables';
@import '../../../../styles/mixins';

section {
	&.key-features {
		@extend %commonContainer;
		padding-top: 96px;
		padding-bottom: 60px;
		display: flex;
		flex-direction: column;
		align-items: center;

		@include breakpoint($mobile, $smallDesktop) {
			overflow: hidden;
			padding-top: 56px;
			padding-bottom: 48px;
		}

		.info {
			text-align: center;
			max-width: 596px;
			margin-bottom: 80px;

			@include breakpoint($mobile, $smallDesktop) {
				margin-bottom: 40px;
			}

			p {
				margin-top: 24px;
				color: $primary_blueberry_200;
			}
		}

		.feature-items {
			max-width: 1018px;
			width: 100%;

			.big-bubble {
				top: -100%;
			}
		}

		.action {
			margin-top: 80px;

			@include breakpoint($mobile, $smallDesktop) {
				display: none;
			}
		}
	}
}
