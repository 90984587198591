@import '../styles/variables';
@import '../styles/mixins';

h1,
h2,
h3 {
	margin: 0;
	padding: 0;
	color: $primary_blueberry_300;
	font-weight: $demiBold;
	font-size: 3em;
	line-height: 1.2;
}

h1, h2 {
	@include breakpoint($mobile, $smallDesktop) {
		font-size: 2em;
	}
}

h3 {
	font-size: 1.5em;
}
