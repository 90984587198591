@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700');
@import '../styles/variables';
@import '../styles/mixins';

* {
	box-sizing: border-box;
	font-family: 'Lexend', sans-serif;
}

html,
body,
#root {
	min-height: 100vh;
	margin: 0px;
	padding: 0px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-weight: $normal;
	color: $primary_blueberry_400;
	width: 100%;
	scroll-behavior: smooth;
	font-size: 16px;

	@include breakpoint($mobile, $smallDesktop) {
		font-size: 12px;
	}

	@include breakpoint($smallDesktop, $desktop) {
		font-size: 14px;
	}
}

//images preloading
body::after {
	position: absolute;
	width: 0;
	height: 0;
	overflow: hidden;
	z-index: -1; // hide images
	content: url('../assets/images/partial-rest.png') url('../assets/images/partial-nist.png')
		url('../assets/images/partial-iso.png'); // load images
}

p {
	font-size: 1em;
	line-height: 1.5;
	margin: 0;
	padding: 0;
}

strong {
	font-weight: $semiBold;
}

.non-scrollable {
	overflow: hidden;
}

::-webkit-scrollbar {
	border-radius: 24px;
	width: 8px;
	height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	background: $primary_lavender_50;
	border-radius: 24px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: $primary_lavender_75;
	border-radius: 24px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: $primary_lavender_75;
	cursor: pointer;
}
