@import '../../../../styles/variables';
@import '../../../../styles/mixins';

section {
	&.platform {
		background-color: $primary_lavender_25;
		position: relative;

		.container {
			@extend %commonContainer;
			padding-top: 96px;
			padding-bottom: 96px;
		}

		h2 {
			margin-bottom: 56px;

			@include breakpoint($mobile, $smallDesktop) {
				margin-bottom: 40px;
			}
		}

		@include breakpoint($mobile, $smallDesktop) {
			.container {
				padding: 48px 0;
			}

			h2 {
				padding: 0 16px;
			}
		}

		&:before {
			content: '';
			position: absolute;
			top: -72px;
			left: 0;
			width: 72px;
			height: 156px;
			background-color: $primary_lavender_50;
			border-top-right-radius: 24px;
			border-bottom-right-radius: 24px;
			z-index: 0;

			@include breakpoint($mobile, $smallDesktop) {
				display: none;
			}
		}

		&:after {
			content: '';
			position: absolute;
			top: calc(50% - 56px);
			right: 0;
			width: 160px;
			height: 213px;
			background-color: $primary_lavender_50;
			border-top-left-radius: 24px;
			border-bottom-left-radius: 24px;
			z-index: 0;

			@include breakpoint($mobile, $smallDesktop) {
				display: none;
			}
		}

		.panel {
			background-color: $white;
			border: 1px solid $primary_lavender_50;
			padding: 96px 80px;
			border-radius: 24px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			z-index: 1;
			position: relative;

			h3 {
				margin-bottom: 16px;
			}

			.rounded-img {
				border-radius: 10px;
			}

			@include breakpoint($mobile, 600px) {
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				padding: 16px 24px 32px;

				&.policies,
				&.trustHub {
					margin-left: 16px;
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
					padding-right: 0;
				}

				&.controls {
					flex-direction: column-reverse;
					margin-right: 16px;
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
					padding-left: 0;

					.info {
						padding-left: 24px;
					}
				}
			}

			@include breakpoint(600px, $desktop) {
				&.controls {
					.info {
						padding-left: 24px;
					}
				}
			}

			.info {
				max-width: 416px;
				position: relative;
				top: -35px;

				@include breakpoint($mobile, $smallDesktop) {
					padding: 16px 16px 24px 0;
					top: 0;
				}
			}

			& + .panel {
				margin-top: 56px;
			}

			&.policies,
			&.trustHub {
				.image {
					transform: translateX(60px);
				}
			}

			&.controls {
				.image {
					transform: translateX(-60px);
				}
			}

			.image {
				position: relative;
				max-width: 520px;

				img {
					width: 100%;
				}

				.banner {
					overflow: visible !important;

					@include breakpoint($mobile, $smallDesktop) {
						text-align: right;
					}
				}

				.label-policies,
				.label-trustHub {
					position: absolute;
					left: -10%;
					top: 65%;
					transform: translateX(-40px) rotate(-5deg);

					@include breakpoint($mobile, $smallDesktop) {
						left: -2%;
					}
				}

				.label-trustHub {
					top: 78%;
				}

				.tag-policies {
					position: absolute;
					right: 13%;
					top: 57%;
					transform: translateX(20px) rotate(0deg);
				}

				.label-controls {
					position: absolute;
					right: -8%;
					top: 75%;
					transform: rotate(10deg);

					@include breakpoint($mobile, $smallDesktop) {
						right: -2%;
					}
				}

				.tag-controls {
					transform: rotate(30deg);
					position: absolute;
					left: 35%;
					top: 70%;
					transform: translateX(-20px) rotate(10deg);
				}

				.tag-controls-empty {
					transform: rotate(-30deg);
					position: absolute;
					left: 35%;
					top: 79%;
					width: 20px;
					height: 8px;
					transform: translateX(-20px) rotate(-5deg);

					@include breakpoint($mobile, $smallDesktop) {
						width: 15px;
						height: 6px;
					}
				}
			}

			&.animate {
				&.policies,
				&.trustHub {
					.image {
						transform: translateX(0);
						transition: all 0.6s ease-in-out;

						.label-policies,
						.label-trustHub {
							transform: translateX(0) rotate(6deg);
							transition: all 1.2s ease-in-out;
						}

						.tag-policies {
							transform: translateX(-10px) rotate(-15deg);
							transition: all 1.2s ease-in-out;
						}
					}
				}

				&.controls {
					.image {
						transform: translateX(0);
						transition: all 0.6s ease-in-out;

						.label-controls {
							transform: rotate(-6deg);
							transition: all 1.2s ease-in-out;
						}

						.tag-controls {
							transform: translateX(0) rotate(-15deg);
							transition: all 1.2s ease-in-out;
						}

						.tag-controls-empty {
							transform: translateX(0) rotate(25deg);
							transition: all 1.2s ease-in-out;
						}
					}
				}
			}
		}
	}
}
