@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.platform-label {
	background: linear-gradient(90deg, #5d48dd 38.86%, #8a7be6 100%);
	border-radius: 8px;
	width: 170px;
	height: 32px;
	padding: 8px 8px 8px 20px;
	display: flex;
	align-items: center;

	@include breakpoint($mobile, $smallDesktop) {
		zoom: 0.7;
	}

	.text {
		font-weight: $semiBold;
		font-size: 10px;
		color: $white;
		padding-left: 10px;
	}

	&:before {
		content: '';
		display: inline-block;
		width: 16px;
		height: 16px;
		background-size: contain;
		background-repeat: no-repeat;
	}

	&.policies {
		&:before {
			background-image: url('../../../assets/images/icon-policies.svg');
		}
	}

	&.controls {
		&:before {
			background-image: url('../../../assets/images/icon-controls.svg');
		}
	}

	&.trustHub {
		&:before {
			background-image: url('../../../assets/images/icon-shield.svg');
		}
	}
}
