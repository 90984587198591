@import '../styles/mixins';

.landing {
	padding-top: 80px;
	min-width: 320px;
	overflow: hidden;

	@include breakpoint($mobile, $smallDesktop) {
		padding-top: 48px;
	}
}
