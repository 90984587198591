@import '../../../styles/variables';
@import '../../../styles/mixins';

.contacts {
	padding-top: 35px;
	padding-bottom: 30px;
	background-color: $primary_lavender_50;
	box-shadow: inset 0 4px 6px -6px rgba(0, 0, 0, 0.2);

	@include breakpoint($mobile, $desktop) {
		padding-top: 25px;
		padding-bottom: 50px;
	}

	@include breakpoint($mobile, $smallDesktop) {
		padding-bottom: 90px;
	}

	.container {
		@extend %commonContainer;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		@include breakpoint($mobile, $desktop) {
			flex-direction: column;
			align-items: flex-start;
		}

		.logo {
			max-height: 24px;
			max-width: 180px;

			@include breakpoint($mobile, $desktop) {
				margin-bottom: 28px;
				max-width: 140px;
			}

			img {
				width: 100%;
			}
		}

		.rights,
		.rights-mobile {
			@extend %commonContainer;
			font-size: 12px;
			color: $primary_blueberry_200;
		}

		.rights {
			margin-top: 8px;

			@include breakpoint($mobile, $desktop) {
				display: none;
			}
		}

		.rights-mobile {
			display: none;

			@include breakpoint($mobile, $desktop) {
				display: block;
				margin-top: 16px;
				padding-left: 6px;
			}
		}

		.bottom-navigation {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			margin-top: -6px;

			.item {
				padding: 8px 10px;
				font-size: 16px;
				color: $primary_blueberry_300;
				cursor: pointer;
				text-decoration: none;

				&:hover {
					text-shadow:
						0 0 0.5px $primary_blueberry_300,
						0 0 0.5px $primary_blueberry_300,
						0 0 0.5px $primary_blueberry_300;
				}

				@include breakpoint($mobile, $desktop) {
					font-size: 14px;
				}
			}

			@include breakpoint($mobile, $smallDesktop) {
				flex-direction: column;

				.item {
					padding-left: 6px;
				}
			}

			@include breakpoint($mobile, $desktop) {
				margin-bottom: 16px;
			}
		}

		.contact {
			display: flex;
			flex-direction: column;
			margin-top: -6px;

			.item {
				padding: 6px;
				font-size: 16px;
				color: $primary_blueberry_300;

				@include breakpoint($mobile, $desktop) {
					font-size: 14px;
				}
			}

			a.item {
				cursor: pointer;
				text-decoration: none;

				&:hover {
					text-shadow:
						0 0 0.5px $primary_blueberry_300,
						0 0 0.5px $primary_blueberry_300,
						0 0 0.5px $primary_blueberry_300;
				}
			}
		}
	}
}
